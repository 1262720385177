export const environment = {
  production: false,
  staging: false,
  apiBaseUrl: 'https://api-qas.b2bbee.ligafacens.com',
  apiCepUrl: 'https://viacep.com.br',
  facebookBaseUrl: 'https://www.facebook.com/',
  instagramBaseUrl: 'https://www.instagram.com/',
  whatsappBaseUrl: 'https://wa.me/55',
  config: {
    redirectToWhenAuthenticated: '/home',
    redirectToWhenUnauthenticated: '/login',
  },
  apiKeys: {
    address: {
      base: '/addresses',
      byId: '/addresses/{addressId}',
      citiesRegistered: '/addresses/cities-registered'
    },
    authentication: {
      login: '/auth/local',
      refreshToken: '/auth/refresh',
    },
    banner: {
      base: '/banners',
      byId: '/banners/{bannerId}',
    },
    category: {
      base: '/categories',
      byId: '/categories/{categoryId}',
    },
    company: {
      base: '/companies',
      byId: '/companies/{companyId}',
      related: '/companies/{companyId}/related'
    },
    companiesKeyword: {
      base: '/companies-keywords',
      byId: '/companies-keywords/{companyKeywordId}'
    },
    companiesRatings: {
      base: '/companies-ratings',
      byId: '/companies-ratings/{companyRatingsId}',
      countByScore: '/companies-ratings/{companyId}/count-by-score'
    },
    complaints: {
      base: '/complaints',
      byId: '/complaints/{complaints}',
    },
    contact: {
      base: '/contact',
      byId: '/contact/{contactId}',
    },
    contactUs: {
      base: '/contact-us',
      byId: '/contact-us/{contactUsId}',
    },
    faqGroup: {
      base: '/faqs-groups',
      byId: '/faqs-groups/{faqGroupId}'
    },
    faqCategory: {
      base: '/faqs-categories',
      byId: '/faqs-categories/{faqCategoryId}'
    },
    faqTopic: {
      base: '/faqs-topics',
      byId: '/faqs-topics/{faqTopicId}'
    },
    keyword: {
      base: '/key-words',
      byId: '/key-words/{keywordId}'
    },
    media: {
      videos: '/medias/presigned/videos',
      images: '/medias/presigned/images'
    },
    payment: {
      base: '/payment',
      byId: '/payment/{paymentId}',
      featured: '/payment/featured',
      subscription: '/payment/subscription',
      subscriptionFreePlan: '/payment/subscription/free-plan',
      cancelSubscription: '/payment/subscription/{paymentId}/cancel',
      webhook: '/payment/webhook',
      countByStatus: '/payment/users/{userId}/count-by-status'
    },
    product: {
      base: '/products',
      byId: '/products/{productId}',
      highlight: '/products/{productId}/highlight',
      countByStatus: '/products/company/{companyId}/count-by-status',
      feed: '/products/feed',
      my: '/products/my'
    },
    rating: {
      base: '/companies-ratings',
      byId: '/companies-ratings/{ratingId}',
    },
    productsKeyword: {
      base: '/products-keywords',
      byId: '/products-keywords/{productKeywordId}'
    },
    productsMedia: {
      base: '/products-medias',
      byId: '/products-medias/{productMediaId}'
    },
    user: {
      base: '/users',
      byId: '/users/{userId}',
      getMe: '/users/me',
      verify: '/users/verify/{code}',
    },
    userPassword: {
      sendEmail: '/users-password/forgot/email/{email}',
      resetPassword: '/users-password/reset/{code}',
      changePassword: '/users-password/change',
    },
    plans: {
      base: '/plans',
      byId: '/plans/{plansId}',
      summary: '/plans/users/{userId}/summary',
    },
    plansAccess: {
      base: '/plans-accesses',
      byId: '/plans-accesses/{plansAccessId}',
    },
    access: {
      base: '/accesses',
      byId: '/accesses/{accessesId}',
    },
    version: {
      base: '/versions/info'
    }
  },
  storageKeys: {
    userToken: 'USER_TOKEN',
    userInformation: 'USER_INFO',
  },
};
